<template>
  <div class="left-block letest-post">
    <h3>Latest posts</h3>
    <ul>
      <li v-for="post in posts" :key="post.id" :post="post">
        <router-link
          :to="{ name: 'article', params: { id: post.id } }"
          title=""
        >
          <img :src="post.data().images[0]" alt="post1" />
          <p class="max-lines" v-html="post.data().content"></p>
          <span>9th September 2016</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { postsCollection } from "@/includes/firebase";

export default {
  name: "LatestPosts",
  props: ["post"],
  data() {
    return {
      posts: [],
    };
  },
  methods: {
    readData() {
      this.posts = [];
      postsCollection.get().then((querySnapshot) => {
        // this.products = querySnapshot;
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          this.posts.push(doc);
        });
      });
    },
  },
  mounted() {
    this.readData();
  },
};
</script>

<style scoped>
.blog-item-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.max-lines {
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 5.8em;
  line-height: 1.8em;
}
.letest-post {
  background-color: #fff;
  padding-bottom: 0px;
}
.letest-post ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.letest-post ul li {
  padding: 11px 5px 11px 10px;
  border-bottom: 1px dashed #b6b6b6;
  border-top: 1px dashed #fff;
}
.letest-post ul li:first-child {
  padding-top: 10px;
}
.letest-post ul li img {
  float: left;
  margin-right: 10px;
  width: 100px;
  height: 76px;
  margin-top: 5px;
}
.letest-post ul li a {
  color: #201f1f;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
}
.letest-post ul li:hover {
  background-color: #f1f1f1;
}
.letest-post ul li:hover a {
  color: #000;
}
.letest-post ul li p {
  margin-bottom: 3px;
  font-size: 13px;
}
.letest-post ul li a span {
  color: #565656;
}
</style>
