<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs :title="post.title" />
  <!-- Breadcroumbs end -->
  <!-- Single Blog start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-9">
          <!-- Blog content start -->
          <div class="single-blog-details">
            <img
              :src="post.images"
              class="img-responsive single-blog-img"
              alt=""
            />
            <div class="single-blog-content">
              <h2>{{ post.title }}</h2>
              <h6>Posted on 8 March 2015 by Mike Ross</h6>
              <div class="blog-post-by">
                <ul>
                  <li><i class="fa fa-eye"></i>20 View</li>
                  <li><i class="fa fa-comment"></i>12 comments</li>
                  <li><i class="fa fa-share-alt"></i>50 share</li>
                  <li>
                    <i class="fa fa-tag"></i
                    ><span
                      class="p-1"
                      v-for="(tag, index) in post.tags"
                      :key="index"
                      >{{ tag }}</span
                    >
                  </li>
                </ul>
              </div>
              <p v-html="post.content"></p>
            </div>
          </div>
          <!-- Blog content end -->

          <div class="space60"></div>

          <!-- Related blog start -->
          <div class="you-may-like">
            <h4 class="mb20 text-uppercase">You may also like</h4>
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="blog-item wow fadeInUp">
                  <a href="#" title="">
                    <div class="blog-item-bg related-blog-bg-1"></div>
                    <div class="blog-content">
                      <h3>
                        All Shipping container are international transport
                      </h3>
                      <h6>Posted on 8 March 2015 by Mike Ross</h6>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="blog-item wow fadeInUp">
                  <a href="#" title="">
                    <div class="blog-item-bg related-blog-bg-2"></div>
                    <div class="blog-content">
                      <h3>
                        All Shipping container are international transport
                      </h3>
                      <h6>Posted on 8 March 2015 by Mike Ross</h6>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Related blog end -->

          <!-- Comment start -->
          <div class="comment-sec">
            <h4 class="mb20 text-uppercase">3 Thoughts on “Single Post”</h4>
            <div class="media">
              <img src="../assets/images/comment-pic1.jpg" alt="Commnet" />
              <div class="media-body">
                <h6 class="mt-0">
                  Kate Winless
                  <span
                    ><i class="fa fa-clock-o"></i> 14th Feb 2015 at 9.00
                    am</span
                  >
                </h6>
                <p>
                  Phasellus odio dolor, pretium sit amet aliquam a, gravida eget
                  dui. Pellentesque ipsum sque vitae ut ligula. Ut luctus
                  fermentum commodo. Mauris eget justo turpis, eget fringilla.
                  Duis lobortis cursus mi vel tristique.
                </p>
                <a href="" title="">Reply</a>
                <div class="media mt-3">
                  <img src="../assets/images/comment-pic2.jpg" alt="" />
                  <div class="media-body">
                    <h6 class="mt-0">Kate Winless</h6>
                    <p>
                      Phasellus odio dolor, pretium sit amet aliquam a, gravida
                      eget dui. Pellentesque ipsum sque vitae ut ligula. Ut
                      luctus fermentum commodo. Mauris eget justo turpis, eget
                      fringilla. Duis lobortis cursus mi vel tristique.
                    </p>
                    <a href="" title="">Reply</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="media">
              <img src="../assets/images/comment-pic1.jpg" alt="Commnet" />
              <div class="media-body">
                <h6 class="mt-0">
                  Kate Winless
                  <span
                    ><i class="fa fa-clock-o"></i> 14th Feb 2015 at 9.00
                    am</span
                  >
                </h6>
                <p>
                  Phasellus odio dolor, pretium sit amet aliquam a, gravida eget
                  dui. Pellentesque ipsum sque vitae ut ligula. Ut luctus
                  fermentum commodo. Mauris eget justo turpis, eget fringilla.
                  Duis lobortis cursus mi vel tristique.
                </p>
                <a href="" title="">Reply</a>
              </div>
            </div>
          </div>
          <!-- Comment end -->

          <!-- Comment form start -->
          <div class="comment-form">
            <h3>Leave a Comment</h3>
            <p>
              Your email address will not be published. Required fields are
              marked
            </p>
            <form action="#">
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div class="col-12 col-lg-6">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <textarea class="form-control" placeholder="Message"></textarea>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-submit">Submit</button>
              </div>
            </form>
          </div>
          <!-- Comment form end -->
        </div>
        <div class="col-12 col-lg-3">
          <div class="left-block left-menu">
            <h3>Category</h3>
            <ul>
              <li class="active">
                <a href="" title="Beehive wood">Beehive wood</a>
              </li>
              <li><a href="" title="Beehive styropor">Beehive styropor</a></li>
              <li><a href="" title="Hive parts">Hive parts</a></li>
              <li><a href="" title="Nucleus hive">Nucleus hive</a></li>
              <li><a href="" title="Queen excluder">Queen excluder</a></li>
              <li><a href="" title="Frames">Frames</a></li>
              <li><a href="" title="Honey Processing">Honey Processing</a></li>
              <li><a href="" title="Honey extractors">Honey extractors</a></li>
              <li><a href="" title="Clothing">Clothing</a></li>
              <li><a href="" title="Queen Rearing">Queen Rearing</a></li>
              <li><a href="" title="Hive hardware">Hive hardware</a></li>
              <li><a href="" title="Beewax">Beewax</a></li>
              <li><a href="" title="Packaging">Packaging</a></li>
              <li><a href="" title="Feed for bees">Feed for bees</a></li>
            </ul>
          </div>
          <LatestPosts />
          <div class="left-block blog-tags">
            <h3>Tags</h3>
            <a href="#" title="Beekeeping Hive">Beekeeping Hive</a>
            <a href="#" title="Smoker">Smoker</a>
            <a href="#" title="Jackate">Jackate</a>
            <a href="#" title="Beekeeping Brush">Beekeeping Brush</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Single Blog End -->

  <Newsletter />
</template>

<script>
// @ is an alias to /src

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";
import LatestPosts from "@/components/LatestPosts.vue";
import { auth, postsCollection } from "@/includes/firebase";

export default {
  name: "Article",
  data() {
    return {
      post: {},
    };
  },
  components: {
    Breadcrumbs,
    Newsletter,
    LatestPosts,
  },
  async beforeRouteEnter(to, from, next) {
    const docSnapshot = await postsCollection.doc(to.params.id).get();

    next((vm) => {
      if (!docSnapshot.exists) {
        vm.$router.push({ name: "home" });
        return;
      }

      // eslint-disable-next-line no-param-reassign
      vm.post = docSnapshot.data();
    });
  },
  methods: {},
  mounted() {
    // eslint-disable-next-line no-undef
    $("a.gallery_link").on("click", function (event) {
      event.preventDefault();

      // var gallery = '#p-details-gallery'; //$(this).attr('id');
      // eslint-disable-next-line no-undef
      const gallery = $(this).attr("title");
      // eslint-disable-next-line no-undef
      $(gallery)
        .magnificPopup({
          delegate: "a",
          type: "image",
          gallery: {
            enabled: true,
          },
          zoom: {
            enabled: true,
            duration: 300, // don't foget to change the duration also in CSS
            opener(element) {
              return element.find("img");
            },
          },
        })
        .magnificPopup("open");
    });
    // eslint-disable-next-line no-undef
    $("#p-details-thumb-slide").owlCarousel({
      items: 5,
      loop: true,
      dots: false,
      nav: true,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
      autoplay: false,
      smartSpeed: 1200,
      responsiveClass: true,
      touchDrag: true,
      responsive: {
        0: {
          items: 4,
          nav: false,
        },
        640: {
          items: 5,
          nav: false,
        },
      },
    });
    // eslint-disable-next-line no-undef
    $("#p-details-thumb-slide li").on("click", function () {
      // eslint-disable-next-line no-undef
      $("#p-details-thumb-slide li").removeClass("highlight");
      // eslint-disable-next-line no-undef
      $(this).toggleClass("highlight");
    });
    /* Zoom */

    // eslint-disable-next-line no-undef
    const easyzoom = $(".easyzoom").easyZoom();

    // Setup thumbnails example
    const api1 = easyzoom.filter(".easyzoom--with-thumbnails").data("easyZoom");
    // eslint-disable-next-line no-undef
    // eslint-disable-next-line no-undef
    $(".thumbnails").on("click", "a", function (e) {
      // eslint-disable-next-line no-undef
      const $this = $(this);

      e.preventDefault();

      // Use EasyZoom's `swap` method
      // eslint-disable-next-line no-undef
      api1.swap($this.data("standard"), $this.attr("href"));
    });
  },
};
</script>
